import React, { useEffect, useState } from 'react'
import { FaArrowTrendUp } from "react-icons/fa6";
import australiaFlag from '../../../assets/img/australiaFlag.png'
import newTickets from '../../../assets/img/newTickets.png'
import openTickets from '../../../assets/img/openTickets.png'
import respTime from '../../../assets/img/respTime.png'
import tracker from '../../../assets/img/tracker.png'
import dp from '../../../assets/img/dp.png'
import harb from '../../../assets/img/harb.png'
import { GoDotFill } from "react-icons/go";
import { FaRegUser } from "react-icons/fa6";
import { SlHome } from "react-icons/sl";
import { BsThreeDotsVertical } from "react-icons/bs";
import PiGraph from '../../../components/Graphs/PiGraph';
import AreaGraph from '../../../components/Graphs/AreaGraph';
import Dropdown from '../UserManagement/Dropdown';
import userServices from 'services/httpService/userAuth/userServices';
import StatusMenu from 'components/StatusMenu/StatusMenu';
import { FaRegStar, FaStar, FaStarHalfAlt } from 'react-icons/fa';
import Rating from 'react-rating';



const AdminDashboard = ({ setStepsAndDetail, allProperty }) => {

    console.log("dashallProperty@@@@@@", allProperty)

    const [allUsers, setAllUsers] = useState([]);
    const [allProperties, setAllProperties] = useState([]);
    const [allReservations, setAllReservations] = useState([]);
    const [ActivatedProperties, setActivatedProperties] = useState([])
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    //options for date format
    const options = {
        hour: '2-digit',
        minute: '2-digit',
        timeZone: 'UTC'
    };

    // Async function to fetch all users from the '/userAuth/allusers' endpoint.
    const getUsers = async () => {
        try {
            let res = await userServices.commonGetService('/userAuth/allusers');
            return res.data;
        } catch (error) {
            throw error;
        }
    };

    // Async function to fetch all properties available to an admin from the '/property/getAllPropertyAvailable' endpoint.
    const getProperties = async () => {
        try {
            let res = await userServices.commonGetService('/property/getAllPropertyAvailable?role=admin');
            return res.data?.data;
        } catch (error) {
            throw error;
        }
    };

    // Async function to fetch reservation data for an admin from the '/reservation/reservations/id' endpoint.
    const getReservation = async () => {
        try {
            let res = await userServices.commonGetService('/reservation/reservations/id?role=admin');
            return res.data;
        } catch (error) {
            throw error;
        }
    };

    // Function to fetch all necessary data (users, properties, reservations) concurrently.
    const fetchData = async () => {
        try {
            const [users, properties, reservations] = await Promise.all([
                getUsers(),
                getProperties(),
                getReservation(),
            ]);
            setAllUsers(users);
            // Filter the properties to get only those that are available.
            let ActivatedProperties = properties.length > 0 && properties.filter(elm => elm.isAvailable == true)
            setActivatedProperties(ActivatedProperties)
            setAllProperties(properties);
            setAllReservations(reservations);
        } catch (error) {
            setError(error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error.message}</div>;

    const formatedDate = (timeStamp) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        const date = new Date(timeStamp);
        return date.toLocaleDateString(undefined, options);
    };

    console.log("allProperties@@@@@", allProperties)
    console.log("allReservations@@@@@", allReservations);
    return (
        <div>
            {/* <div className='flex justify-between items-center px-5 py-3 border-b border-gray-300'>
                <div>
                    <h1 className='font-bold'>Hi, James B.</h1>
                    <h1 className='text-[#4B4B4B]'>Welcome Back!</h1>
                </div>
                <div>
                    <Dropdown />
                </div>
            </div> */}

            <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 px-5 py-3 gap-3'>
                <div className=' border-2 border-[#E9E9E9]  rounded-xl'>
                    <div className='p-5'>
                        <h1 className='font-bold text-[#4B4B4B]'>Total Users</h1>
                        <div className='flex justify-between items-center '>
                            <div>
                                <h1 className='font-bold text-[24px]'>{allUsers?.length}</h1>
                            </div>
                            <div className='flex gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <h1 className='text-[#40CA5E]'>12.00%</h1>
                            </div>
                        </div>
                    </div>
                    <hr className='text-[#E9E9E9]' />

                    <div className='flex items-center justify-between px-5 py-3'>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>New</h1>
                            <h1 className='font-bold text-[16px]'>21</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>
                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>Host</h1>
                            <h1 className='font-bold text-[16px]'>231k+</h1>
                            <h1 className='text-[#C0392D] bg-red-100 px-3 py-1 rounded'>%00</h1>
                        </div>
                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>Guest</h1>
                            <h1 className='font-bold text-[16px]'>500k+</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                    </div>

                </div>
                <div className=' border-2 border-[#E9E9E9]  rounded-xl'>
                    <div className='p-5'>
                        <h1 className='font-bold text-[#4B4B4B]'>Listed Properties</h1>
                        <div className='flex justify-between items-center '>
                            <div>
                                <h1 className='font-bold text-[24px]'>{ActivatedProperties?.length}</h1>
                            </div>
                            <div className='flex gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <h1 className='text-[#40CA5E]'>12.00%</h1>
                            </div>
                        </div>
                    </div>
                    <hr className='text-[#E9E9E9]' />

                    <div className='flex items-center justify-between px-5 py-3'>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>All Properties</h1>
                            <h1 className='font-bold text-[16px]'>{allProperties?.length}</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>New listed</h1>
                            <h1 className='font-bold text-[16px]'>500k+</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                    </div>

                </div>
                <div className=' border-2 border-[#E9E9E9]  rounded-xl'>
                    <div className='p-5'>
                        <h1 className='font-bold text-[#4B4B4B]'>Booked Properties</h1>
                        <div className='flex justify-between items-center '>
                            <div>
                                <h1 className='font-bold text-[24px]'>{allReservations?.length}</h1>
                            </div>
                            <div className='flex gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <h1 className='text-[#40CA5E]'>12.00%</h1>
                            </div>
                        </div>
                    </div>
                    <hr className='text-[#E9E9E9]' />

                    <div className='flex items-center justify-between px-5 py-3'>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>All</h1>
                            <h1 className='font-bold text-[16px]'>21</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>
                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>Check In</h1>
                            <h1 className='font-bold text-[16px]'>231k+</h1>
                            <h1 className='text-[#C0392D] bg-red-100 px-3 py-1 rounded'>%00</h1>
                        </div>
                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>Check Out</h1>
                            <h1 className='font-bold text-[16px]'>500k+</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                    </div>

                </div>
                {/* <div className=' border-2 border-[#E9E9E9]  rounded-xl'>
                    <div className='p-5'>
                        <h1 className='font-bold text-[#4B4B4B]'>Schedule</h1>
                        <div className='flex justify-between items-center '>
                            <div>
                                <h1 className='font-bold text-[24px]'>665</h1>
                            </div>
                            <div className='flex gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <h1 className='text-[#40CA5E]'>12.00%</h1>
                            </div>
                        </div>
                    </div>
                    <hr className='text-[#E9E9E9]' />

                    <div className='flex items-center justify-between px-5 py-3'>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>New Res</h1>
                            <h1 className='font-bold text-[16px]'>21</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>Cancel</h1>
                            <h1 className='font-bold text-[16px]'>500k+</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                    </div>

                </div> */}
                <div className=' border-2 border-[#E9E9E9]  rounded-xl'>
                    <div className='p-5'>
                        <h1 className='font-bold text-[#4B4B4B]'>Total Earning</h1>
                        <div className='flex justify-between items-center '>
                            <div>
                                <h1 className='font-bold text-[24px]'>
                                    {allReservations.length > 0 && allReservations
                                        .reduce((acc, item) => {
                                            return item.status === 'completed' ? acc + item.offerState.serviceFee : acc;
                                        }, 0)
                                        .toFixed(2)}
                                </h1>
                            </div>
                            <div className='flex gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <h1 className='text-[#40CA5E]'>12.00%</h1>
                            </div>
                        </div>
                    </div>
                    <hr className='text-[#E9E9E9]' />

                    <div className='flex items-center justify-between px-5 py-3'>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>New</h1>
                            <h1 className='font-bold text-[16px]'>21</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>Host</h1>
                            <h1 className='font-bold text-[16px]'>500k+</h1>
                            <h1 className='text-[#C0392D] bg-red-100 px-3 py-1 rounded'>%00</h1>
                        </div>

                    </div>

                </div>

            </div>
            {/* <div className='grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 px-5 py-3 gap-3'>
                <div className=' border-2 border-[#E9E9E9]  rounded-xl'>
                    <div className='p-5'>
                        <h1 className='font-bold text-[#4B4B4B]'>Total Earning</h1>
                        <div className='flex justify-between items-center '>
                            <div>
                            <h1 className='font-bold text-[24px]'>
  {allReservations.length > 0 && allReservations.reduce((acc, item) => {
    return item.status === 'completed' ? acc + item.offerState.serviceFee : acc;
  }, 0)}
</h1>

                            </div>
                            <div className='flex gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <h1 className='text-[#40CA5E]'>12.00%</h1>
                            </div>
                        </div>
                    </div>
                    <hr className='text-[#E9E9E9]' />

                    <div className='flex items-center justify-between px-5 py-3'>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>New</h1>
                            <h1 className='font-bold text-[16px]'>21</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>Host</h1>
                            <h1 className='font-bold text-[16px]'>500k+</h1>
                            <h1 className='text-[#C0392D] bg-red-100 px-3 py-1 rounded'>%00</h1>
                        </div>

                    </div>

                </div>
                <div className=' border-2 border-[#E9E9E9]  rounded-xl'>
                    <div className='p-5'>
                        <h1 className='font-bold text-[#4B4B4B]'>Host Earning</h1>
                        <div className='flex justify-between items-center '>
                            <div>
                                <h1 className='font-bold text-[24px]'>652</h1>
                            </div>
                            <div className='flex gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <h1 className='text-[#40CA5E]'>12.00%</h1>
                            </div>
                        </div>
                    </div>
                    <hr className='text-[#E9E9E9]' />

                    <div className='flex items-center justify-between px-5 py-3'>

                        <div className='flex flex-col gap-[4px]' >
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>New listed</h1>
                            <h1 className='font-bold text-[16px]'>21</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>All properties</h1>
                            <h1 className='font-bold text-[16px]'>231k+</h1>
                            <h1 className='text-[#C0392D] bg-red-100 px-3 py-1 rounded'>%00</h1>
                        </div>

                    </div>

                </div>
                <div className=' border-2 border-[#E9E9E9]  rounded-xl'>
                    <div className='p-5'>
                        <h1 className='font-bold text-[#4B4B4B]'>Guest Spend</h1>
                        <div className='flex justify-between items-center '>
                            <div>
                                <h1 className='font-bold text-[24px]'>98</h1>
                            </div>
                            <div className='flex gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <h1 className='text-[#40CA5E]'>12.00%</h1>
                            </div>
                        </div>
                    </div>
                    <hr className='text-[#E9E9E9]' />

                    <div className='flex items-center justify-between px-5 py-3'>

                        <div className='flex flex-col gap-[4px]' >
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>All</h1>
                            <h1 className='font-bold text-[16px]'>21</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                        <div className='flex flex-col gap-[4px]' >
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>Check In</h1>
                            <h1 className='font-bold text-[16px]'>231k+</h1>
                            <h1 className='text-[#C0392D] bg-red-100 px-3 py-1 rounded'>%00</h1>
                        </div>

                    </div>

                </div>
                <div className=' border-2 border-[#E9E9E9]  rounded-xl'>
                    <div className='p-5'>
                        <h1 className='font-bold text-[#4B4B4B]'>Support Tickets</h1>
                        <div className='flex justify-between items-center '>
                            <div>
                                <h1 className='font-bold text-[24px]'>665</h1>
                            </div>
                            <div className='flex gap-2'>
                                <FaArrowTrendUp className='text-[#40CA5E]' />
                                <h1 className='text-[#40CA5E]'>12.00%</h1>
                            </div>
                        </div>
                    </div>
                    <hr className='text-[#E9E9E9]' />

                    <div className='flex items-center justify-between px-5 py-3'>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>New Res</h1>
                            <h1 className='font-bold text-[16px]'>21</h1>
                            <h1 className='text-[#40CA5E] bg-[#DFF7E9] px-3 py-1 rounded'>%00</h1>
                        </div>

                        <div className='flex flex-col gap-[4px]'>
                            <h1 className='text-[12px] font-bold text-[#4B4B4B]'>Cancel</h1>
                            <h1 className='font-bold text-[16px]'>231k+</h1>
                            <h1 className='text-[#C0392D] bg-red-100 px-3 py-1 rounded'>%00</h1>
                        </div>

                    </div>

                </div>

            </div> */}
            <div className='flex lg:flex-row flex-col gap-3 lg:gap-0  justify-between py-3 px-5'>

                <div className='lg:w-[59.5%] w-full flex flex-col  border-2 border-[#DFF7E9] rounded-xl gap-2'>
                    <div className='flex justify-between items-center px-5 pt-2'>
                        <div>
                            <h1>Revenue Summary</h1>
                        </div>
                        <div>
                            <Dropdown />
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-around items-center bg-gray-300 py-3'>
                        <div>
                            <h1 className='text-gray-500 pb-2'>Bookings</h1>
                            <div className='flex md:flex-row flex-col gap-2'>
                                <span>
                                    <h1 className='font-bold text-[14px]'>846</h1>
                                </span>
                                <span className='flex md:gap-2'>
                                    <FaArrowTrendUp className='text-[#40CA5E]' />
                                    <p className='text-[#40CA5E]'>15.00%</p>
                                </span>
                            </div>
                        </div>
                        <div >
                            <h1 className='text-gray-500 pb-2'>Revenue</h1>
                            <div className='flex md:flex-row flex-col gap-2'>
                                <span>
                                    <h1 className='font-bold text-[14px]'>846</h1>
                                </span>
                                <span className='flex md:gap-2'>
                                    <FaArrowTrendUp className='text-[#40CA5E]' />
                                    <p className='text-[#40CA5E]'>15.00%</p>
                                </span>
                            </div>
                        </div>
                        <div >
                            <h1 className='text-gray-500 pb-2'>Revenue</h1>
                            <div className='flex md:flex-row flex-col gap-2'>
                                <span>
                                    <h1 className='font-bold text-[14px]'>846</h1>
                                </span>
                                <span className='flex md:gap-2'>
                                    <FaArrowTrendUp className='text-[#40CA5E]' />
                                    <p className='text-[#40CA5E]'>15.00%</p>
                                </span>
                            </div>
                        </div>
                        <div>
                            <h1 className='text-gray-500 pb-2'>Revenue</h1>
                            <div className='flex md:flex-row flex-col gap-2'>
                                <span>
                                    <h1 className='font-bold text-[14px]'>846</h1>
                                </span>
                                <span className='flex md:gap-2'>
                                    <FaArrowTrendUp className='text-[#40CA5E]' />
                                    <p className='text-[#40CA5E]'>15.00%</p>
                                </span>
                            </div>
                        </div>

                    </div>

                    <div>
                        <AreaGraph />
                    </div>

                </div>


                <div className='flex flex-col lg:w-[39.5%] w-full border-2 border-[#DFF7E9] rounded-xl '>
                    <div className='flex items-center justify-between p-2'>
                        <div>
                            <h1 className='font-bold'>Earning by Countries</h1>
                        </div>
                        <div className='flex items-center md:gap-4 gap-4'>
                            <Dropdown />
                            <p className='underline flex text-[14px] md:text-base w-14 md:w-auto'>View All</p>
                        </div>
                    </div>
                    <hr />
                    <div>
                        {[1, 2, 3, 4].map((data, index) => (
                            <div className='flex justify-between p-3 border-b'>
                                <div className='flex items-center gap-3'>
                                    <div>
                                        <img src={australiaFlag}></img>
                                    </div>
                                    <div className='flex flex-col'>
                                        <h1 className='font-bold'>15,54k Points</h1>
                                        <p className='text-gray-400'>Australia</p>
                                    </div>

                                </div>
                                <div className='flex items-center justify-center gap-2'>
                                    <FaArrowTrendUp className='text-[#40CA5E]' />
                                    <h1 className='text-[#40CA5E]'>15.00%</h1>
                                </div>
                            </div>
                        ))}

                    </div>
                </div>
            </div>

            <div className='grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 px-5 gap-5'>

                <div className='border-2 border-[#DFF7E9] rounded-xl'>
                    <div className='flex justify-between items-center px-5 py-3.5'>
                        <div>
                            <h1 className='font-bold'>Activity Timeline</h1>
                        </div>
                        <div>
                            <h1 className='underline font-bold'>View All</h1>
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-between p-3 items-center'>
                        <div className='flex items-center gap-3'>
                            <div className=''>
                                <GoDotFill className='text-[#E8AD21] text-2xl' />
                            </div>
                            <div className='flex flex-col'>
                                <h1 className='font-bold'>Meeting</h1>
                                <p className='text-gray-400 text-sm'>Project meeting with john @10:15am</p>
                            </div>

                        </div>
                        <div
                        >
                            <h1 className='text-gray-400 text-sm'>28/02/24</h1>
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-between p-3 items-center'>
                        <div className='flex items-center gap-3'>
                            <div className=''>
                                <GoDotFill className='text-[#2459BF] text-2xl' />
                            </div>
                            <div className='flex flex-col'>
                                <h1 className='font-bold'>Recent Transactions</h1>
                                <p className='text-gray-400 text-sm'>Transaction via credit</p>
                            </div>

                        </div>
                        <div
                        >
                            <h1 className='text-gray-400 text-sm'>28/02/24</h1>
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-between p-3 items-center'>
                        <div className='flex items-center gap-3'>
                            <div className=''>
                                <GoDotFill className='text-[#58C0D0] text-2xl' />
                            </div>
                            <div className='flex flex-col'>
                                <h1 className='font-bold'>Explore properties</h1>
                                <p className='text-gray-400 text-sm'>Properties Change and edit #645847, #645847</p>
                            </div>

                        </div>
                        <div
                        >
                            <h1 className='text-gray-400 text-sm'>28/02/24</h1>
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-between p-3 items-center'>
                        <div className='flex items-center gap-3'>
                            <div className=''>
                                <GoDotFill className='text-[#AFB5C1] text-2xl' />
                            </div>
                            <div className='flex flex-col'>
                                <h1 className='font-bold'>Meeting</h1>
                                <p className='text-gray-400 text-sm'>Project meeting with john @10:15am</p>
                            </div>

                        </div>
                        <div
                        >
                            <h1 className='text-gray-400 text-sm'>28/02/24</h1>
                        </div>
                    </div>
                    <hr />
                    <div className='flex justify-between p-3 items-center'>
                        <div className='flex items-center gap-3'>
                            <div className=''>
                                <GoDotFill className='text-[#AFB5C1] text-2xl' />
                            </div>
                            <div className='flex flex-col'>
                                <h1 className='font-bold'>Meeting</h1>
                                <p className='text-gray-400 text-sm'>Project meeting with john @10:15am</p>
                            </div>

                        </div>
                        <div
                        >
                            <h1 className='text-gray-400 text-sm'>28/02/24</h1>
                        </div>
                    </div>
                    <hr />
                </div>
                <div className='border-2 border-[#DFF7E9] rounded-xl'>
                    <div className='flex justify-between items-center px-5 py-2'>
                        <div>
                            <h1 className='font-bold'>Exceptions Reasons</h1>
                        </div>
                        <div>
                            <Dropdown />
                        </div>
                    </div>
                    <hr />

                    <div>
                        <PiGraph />
                    </div>

                </div>
                <div className='flex flex-col border-2 border-[#DFF7E9] rounded-xl'>
                    <div className='flex justify-between items-center px-5 py-2'>
                        <div>
                            <h1 className='font-bold'>Support Tracker</h1>
                        </div>
                        <div>
                            <Dropdown />
                        </div>
                    </div>
                    <hr />

                    <div className='flex justify-between px-5 py-7 items-center'>
                        <div className='flex items-center gap-3'>

                            <div className='flex flex-col'>
                                <h1 className='text-gray-400'>Total Tracker</h1>
                                <p className='font-bold text-[32px]'>546</p>
                            </div>

                        </div>
                        <div>
                            <img src={tracker}></img>
                        </div>
                    </div>
                    <hr />

                    <div className='flex  p-3 items-center'>
                        <div className='flex items-center gap-3'>
                            <div className=''>
                                <img src={newTickets} />
                            </div>
                            <div className='flex flex-col'>
                                <h1 className='font-bold'>New Tickets</h1>
                                <p className='text-gray-400 text-sm'>646</p>
                            </div>

                        </div>

                    </div>
                    <hr />
                    <div className='flex  p-3 items-center'>
                        <div className='flex items-center gap-3'>
                            <div className=''>
                                <img src={openTickets} />
                            </div>
                            <div className='flex flex-col'>
                                <h1 className='font-bold'>Open Tickets</h1>
                                <p className='text-gray-400 text-sm'>41</p>
                            </div>

                        </div>

                    </div>
                    <hr />
                    <div className='flex  p-3 items-center'>
                        <div className='flex items-center gap-3'>
                            <div className=''>
                                <img src={respTime} />
                            </div>
                            <div className='flex flex-col'>
                                <h1 className='font-bold'>Response Time</h1>
                                <p className='text-gray-400 text-sm'>1 Day</p>
                            </div>

                        </div>

                    </div>
                    <hr />

                </div>

            </div>

            <div className='mx-5 border-2 border-[#DFF7E9] rounded-xl my-3 '>

                <div className='flex justify-between items-center p-3'>
                    <span className='font-bold'>Recent Booked Reservations</span>
                    <span className='underline'>View All</span>
                </div>

                <div className='overflow-auto'>
                    <table className="table-auto   w-full bg-white ">
                        <thead>
                            <tr className='bg-[#fafbff]'>
                                <th className="px-4 py-2 border-t border-b text-left">
                                    Guests
                                </th>
                                <th className="px-4 py-2 text-left border-t border-b">Res. Code</th>
                                <th className="px-4 py-2 border-t text-left border-b">Check-In</th>
                                <th className="px-4 py-2 border-t text-left border-b">Check-Out</th>
                                <th className="px-4 py-2 border-t  text-left border-b">Amount</th>
                                <th className="px-4 py-2 border-t text-left border-b">Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {allReservations?.slice(-5).reverse().map((item, index) => {

                                const checkInDate = new Date(item.offerState.checkIn);
                                const checkOutDate = new Date(item.offerState.checkOut);
                                const reservationDate = new Date(item.timeStamp);

                                const formattedCheckInDate = checkInDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                                const formattedCheckInTime = checkInDate.toLocaleTimeString('en-US', options);

                                const formattedCheckOutDate = checkOutDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                                const formattedCheckOutTime = checkOutDate.toLocaleTimeString('en-US', options);

                                const formattedreservationDate = reservationDate.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
                                const formattedreservationTime = reservationDate.toLocaleTimeString('en-US', options);
                                return (
                                    <tr key={index}>
                                        <td className="px-4 py-2 border-t border-b font-bold text-sm leading-5 text-black">
                                            <div className="flex items-center">
                                                <img src={item.userId?.pic} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                                                <div className='flex flex-col'>
                                                    <span onClick={() => setStepsAndDetail(item._id)} className="font-medium text-sm leading-5 text-black cursor-pointer">{item.userId?.fname + " " + item.userId?.lname}</span>
                                                    <span className="font-medium text-xs leading-4 text-gray-400">{item.userId?.email}</span>
                                                </div>
                                            </div>
                                        </td>

                                        <td className="px-4 py-2 border-t border-b font-bold text-sm leading-5 text-black">{item._id}</td>
                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <div className='flex flex-col'>
                                                <span className='font-bold'>{formattedCheckInDate}</span>
                                                <span className="font-medium text-xs leading-4 text-gray-400">{formattedCheckInTime}</span>
                                            </div>
                                        </td>
                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black">
                                            <div className="flex flex-col">
                                                <span className='font-bold'>{formattedCheckOutDate}</span>
                                                <span className="font-medium text-xs leading-4 text-gray-400">{formattedCheckOutTime}</span>
                                            </div>
                                        </td>

                                        <td className="px-4 py-2 border-t border-b font-medium text-sm leading-5 text-black w-28">
                                            <div className='flex flex-col'>
                                                <span className='font-bold'>{item.offerState.points} Points</span>
                                            </div>
                                        </td>
                                        <td className="px-4 py-2 border-t border-b font-bold text-sm leading-5 text-black">
                                            <div className=" rounded-full flex justify-center items-center">
                                                <button
                                                    className={`font-extrabold text-xs leading-4 text-center ${item.status === 'pending' ? 'bg-[#DE3434] rounded-full bg-opacity-20 w-[60px] h-[30px] text-[#DE3434]' :
                                                        item.status === 'cancelled' ? 'bg-[#6C6C6C] rounded-full bg-opacity-20 w-[70px] h-[30px] text-[#6C6C6C]' :
                                                            item.status === 'completed' ? 'bg-[#39CB79] rounded-full bg-opacity-20 w-[70px] h-[30px] text-[#39CB79]' : ''
                                                        }`}
                                                >
                                                    {item.status}</button>
                                            </div>
                                            <div className='flex gap-1 text-[#6C6C6C] pt-2'>
                                                <p>{formattedreservationDate} {" | "}</p>
                                                <p>{formattedreservationTime}</p>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>


            </div>

            <div className='mx-5 border-2 border-[#DFF7E9] rounded-xl my-3 '>

                <div className='flex justify-between items-center p-3'>
                    <span className='font-bold'>Recent Listed Properties</span>
                    <span className='underline'>View All</span>
                </div>

                <div className='overflow-auto'>
                    <table className="table-auto   w-full bg-white ">
                        <thead>
                            <tr className='bg-[#fafbff]'>
                                <th className="px-4 py-2 border-t border-b text-left">
                                    Properties
                                </th>
                                <th className="px-4 py-2 text-left border-t border-b">Location</th>
                                <th className="px-4 py-2 border-t text-left border-b">Room</th>
                                <th className="px-4 py-2 border-t text-left border-b">Price</th>
                                <th className="px-4 py-2 border-t text-left border-b">Host</th>
                                <th className="px-4 py-2 border-t text-left border-b">Listing Status</th>
                                <th className="px-4 py-2 border-t  text-left border-b"></th>

                            </tr>
                        </thead>
                        <tbody>
                            {allProperties?.slice(-5).reverse().map((item, index) => {

                                const ratings = item?.reviews?.length && item?.reviews?.map((review) => review.rating);
                                const total = ratings.length > 0 && ratings?.reduce((acc, rating) => acc + rating, 0);
                                const averageRating = ratings.length > 0 ? Number((total / ratings.length).toFixed(1)) : 0;

                                const minPoint = Array.isArray(item.points) && item.points?.length > 0
                                    ? item.points.reduce((min, p) => (p.point < min ? p.point : min), item.points[0].point)
                                    : null;

                                return (
                                    <tr key={index} className='border-t'>
                                        <td className=" w-72 px-4 py-2  flex flex-col items-start">
                                            <div className="flex items-center">
                                                <div className=' w-24 h-20  flex-shrink-0'>
                                                    <img src={item.pics[0]} className="w-full h-full rounded-md object-cover" />
                                                </div>
                                                <div className='flex flex-col ml-5'>
                                                    <div className='flex flex-col'>
                                                        <span onClick={() => setStepsAndDetail(item._id)} className="font-medium text-sm leading-5 text-black cursor-pointer">{item.title}</span>
                                                        {averageRating > 0 ?
                                                            <div className="flex md:flex-row flex-col">
                                                                <Rating
                                                                    initialRating={averageRating}
                                                                    readonly
                                                                    emptySymbol={<FaRegStar color="#d3d3d3" />}
                                                                    halfSymbol={<FaStarHalfAlt color="#ffd700" />}
                                                                    fullSymbol={<FaStar color="#ffd700" />}
                                                                    fractions={2}
                                                                />
                                                                <span>({averageRating})</span>
                                                            </div>
                                                            :
                                                            <span className="text-[#AFB5C1]">Not Rated Yet.</span>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </td>


                                        <td className=" px-4 py-2 font-bold text-sm leading-5 text-black">
                                            <span className='text-gray-500 text-sm'>{item.address}</span>
                                        </td>
                                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                                            <div className='flex flex-col'>
                                                <span className='text-sm text-gray-500'>{item.spaceTypeDetail.bedrooms} bed</span>
                                                <span className='text-sm text-gray-500'>{item.spaceTypeDetail.bathrooms} bath</span>
                                                <span className='text-sm text-gray-500'>{item.spaceTypeDetail.kitchen} kit</span>
                                            </div>
                                        </td>
                                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black w-28">
                                            <div className="flex flex-col">
                                                <span className='font-bold'>{minPoint} Points</span>
                                            </div>
                                        </td>
                                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                                            <div className='flex flex-col'>
                                                <span className='font-bold'>{item.user.fname}{" " + item.user.lname} </span>
                                            </div>
                                        </td>
                                        <td className="px-4 py-2 font-medium text-sm leading-5 text-black">
                                            <div className={`px-4 py-2 font-medium text-sm leading-5 rounded-full text-center
                                        ${item.status === 'Rejected' ? 'text-[#FF0000] bg-[#FF00001A]' : ''} 
                                        ${item.status === 'Active' ? 'text-[#39CB79] bg-[#39CB791A]' : ''} 
                                        ${item.status === 'Pending' ? 'text-[#FFA500] bg-[#FFA5001A]' : ''}`}>
                                                {item.status}
                                            </div>
                                            <br />
                                            <div className='pt-2'>
                                                <span className='text-[#4B4B4B]'>{formatedDate(item.timeStamp)}</span>
                                            </div>
                                        </td>
                                        <td className=" px-4 py-2 border-t border-b font-medium  leading-5 text-black">
                                            <BsThreeDotsVertical />
                                        </td>

                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>

            </div>

        </div >
    )
}

export default AdminDashboard
