import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import PropertyListing from "pages/member/PropertyListing";
import Nofound from "pages/StaticPages/Nofound";
import VerificationPage from "pages/customer/VerificationPage";
import SearchListings from "pages/customer/SearchListings";
import BnbNav from "components/NavBar/BnbNav";
import PropertyDetails from "pages/customer/PropertyDetails";
import ReserveDetails from "pages/customer/PropertyDetails/ReserveDetails";
import ConfirmReservation from "pages/customer/PropertyDetails/ConfirmReservation";
import BookingConfirmation from "pages/customer/BookingConfirmation";
import Footerbn from "pages/Footer/Footerbn";
import Payment from "components/Payment/Payment";
import SendVerificationCode from "components/PhoneVerification/PhoneVerification";
import MyTrip from "pages/customer/MyTrip/MyTrip";
import WishList from "pages/customer/MyTrip/WishList";
import StartListing from "pages/member/PropertyListing/StartListing";
import Chat from "pages/Chat";
import Referrals from "pages/guest/Referrals/Referrals";
import { HostProfile } from "pages/HostProfile/HostProfile";
import TermsAndConditions from "pages/guest/TermsAndConditions";
import PrivacyAndPolicy from "pages/guest/PrivacyAndPolicy";
import Community from "pages/Community";
import { Host } from "pages/Host";
import { GuestAccount } from "pages/guest/guestAccount";
import { HostAccount } from "pages/Host/HostAccount";
import { ProfileV2 } from "components/profile";
import CommunityReply from "pages/Community/CommunityReply";
import ReferralPrograms from "pages/admin/ReferralProgram";
import InfluencerDetails from "pages/admin/InfluencerDetails";
import Refund from "pages/refund/Index";
import Review from "pages/review/Index";
import Pricing from "components/Pricing/Pricing";
import ChangeReservation from "pages/customer/PropertyDetails/ChangeReservation";
import RateandReview from "components/ReviewComponent/RateandReview";
import AdminDashboard from "pages/admin/MainDashboard";
import FinancialTransactions from "pages/admin/FinancialTransactions/FinancialTransactions";
import SupportTicketing from "pages/admin/SupportTicketing/SupportTicketing";
import UserManagement from "pages/admin/UserManagement/userManagement";
import Permissions from "pages/admin/Roles_Permissions";
import ReviewsFeedback from "pages/admin/Reviews/ReviewsFeedback";
import HostDetail from "pages/admin/HostDetail/HostDetail";
import PropertyManagement from "pages/admin/PropertyManagement/PropertyManagement2";
import AdminSettings from "pages/admin/AdminSettings/AdminSettings";
import ReviewResponse from "pages/admin/ReviewResponse/ReviewResponse";
import AdminMessages from "pages/admin/AdminMessages";
import UserManagement2 from "pages/admin/UserManagement/UserManagement2";
import AdminPropertyDetail from "pages/admin/PropertyManagement/AdminPropertyDetail";
import ManageProperty from "pages/admin/PropertyManagement/PropertyManagement";
import Properties from "pages/admin/PropertyManagement/Properties";
import AdminReviews from "pages/admin/Reviews/AdminReviews";
import MyProperties from "pages/guest/MyProperties/MyProperties";
import AdminModal from "pages/admin/AdminModal/AdminModal";
import ReservationManagement from "pages/admin/ReservationManagement/ReservationManagement";
import ReservationCrad from "components/Cards/ReservationCrad";
import UpdatePassword from "pages/auth/UpdatePassword";
import { Account } from "components/Account";
import { PayoutsStepOne } from "components/Account/PaymentsAndPayouts/payouts/payoutsStepOne";
import { PayoutsStepTwo } from "components/Account/PaymentsAndPayouts/payouts/payoutsStepTwo";
import GuestProperty from "pages/customer/PropertyList/GuestProperty";
import PropertyTab from "pages/admin/PropertyManagement/PropertyTab";


import SuccessStripe from "pages/Stripe/SuccessStripe";
import ErrorStripe from "pages/Stripe/ErrorStripe";
import Navbar from "components/Navbars/AuthNavbar";

function Main() {
  let location = useLocation();
  return (
    <>
      {/* {location.pathname !== "/propertylisting" ? <Navbar /> : ""} */}
      {location.pathname !== "/propertylisting" && (
        <>
          <BnbNav />
          <Navbar />
        </>
      )}

      <main>
        <section className="relative w-full h-full min-h-screen ">

          <Routes>
            <Route path="/profile" element={<ProfileV2 />} />
            <Route path="/mytrip" element={<MyTrip />} />
            <Route path="/admindashboard" element={<AdminDashboard />} />
            <Route path="/financialtransactions" element={<FinancialTransactions />} />
            <Route path="/supportticketing" element={<SupportTicketing />} />
            <Route path="/hostdetail" element={<HostDetail />} />
            <Route path="/adminpropertydetail" element={<AdminPropertyDetail />} />
            <Route path="/myproperties" element={<MyProperties />} />
            <Route path="/adminmodal" element={<AdminModal />} />
            <Route path="/reservationcrad" element={<ReservationCrad />} />

            <Route path="/updatepassword" element={<UpdatePassword />} />
            <Route path="/account" element={<Account />} />
            <Route path="/payoutstepone" element={<PayoutsStepOne />} />
            <Route path="/payoutsteptwo" element={<PayoutsStepTwo />} />

            <Route path="/wishlist" element={<WishList />} />
            <Route path="/listing" element={<StartListing />} />
            <Route path="/community" element={<Community />} />
            <Route path="/refund" element={<Refund />} />
            <Route path="/refund/:id" element={<Refund />} />
            <Route path="/review" element={<Review />} />
            <Route path="/rateandreview" element={<RateandReview />} />
            <Route path="/pricing" element={<Pricing />} />
            <Route path="/changereservation" element={<ChangeReservation />} />
            <Route path="/communityreply/:id" element={<CommunityReply />} />
            {/* <Routes> */}
            <Route path="/propertylisting" element={<PropertyListing />} />
            <Route path="/guestAccount" element={<GuestAccount />} />
            <Route path="/hostAccount" element={<HostAccount />} />
            <Route path="/hostprofile/:id" element={<HostProfile />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="/privacy" element={<PrivacyAndPolicy />} />

            {/* Host root */}
            {/* <Route path="/host" element={<Host />} /> */}
            <Route path="/dashboard" element={<Host />} />
            <Route path="/usermanagement" element={<UserManagement />} />
            <Route path="/propertyTab" element={<PropertyTab />} />

            <Route path="/reservationmanagement" element={<ReservationManagement />} />
            <Route path="/permissions" element={<Permissions />} />
            <Route path="/reviewsfeedback" element={<ReviewsFeedback />} />
            <Route path="/propertymanagement" element={<PropertyManagement />} />
            <Route path="/adminsettings" element={<AdminSettings />} />
            <Route path="/reviewresponse" element={<ReviewResponse />} />
            <Route path="/usermanagement2" element={<UserManagement2 />} />
            <Route path="/propertiessteps" element={<Properties />} />
            <Route path="/adminreviews" element={<AdminReviews />} />
            <Route path="/verification" element={<VerificationPage />} />
            <Route path="/search/:lat/:long" element={<SearchListings />} />
            <Route path="/BnbNav" element={<BnbNav />} />
            <Route path="/footer" element={<Footerbn />} />
            <Route path="/my-properties" element={<GuestProperty />} />

            <Route path="/propertydetails/:id" element={<PropertyDetails />} />
            <Route
              path="/bookingconfirmation"
              element={<BookingConfirmation />}
            />

            <Route path="/ReserveDetails" element={<ReserveDetails />} />
            <Route
              path="/ConfirmReservation"
              element={<ConfirmReservation />}
            />
            <Route path="/payment" element={<Payment />} />
            <Route
              path="/phoneverification"
              element={<SendVerificationCode />}
            />
            <Route path="/payment" element={<Payment />} />
            <Route path="/paymentsuccess" element={<SuccessStripe />} />
            <Route path="/paymenterror" element={<ErrorStripe />} />
            <Route path="/referrals" element={<Referrals />} />
            <Route path="/referrals/:id" element={<Referrals />} />
            <Route
              path="/phoneverification"
              element={<SendVerificationCode />}
            />

            <Route path='/chat'
              element={<Chat />}
            />
            <Route path='/property_management'
              element={<ManageProperty />}
            />

            <Route path='/referral_programs'
              element={<ReferralPrograms />}
            />
            <Route path='/guest_details'
              element={<InfluencerDetails />}
            />
            <Route path='/adminmessages'
              element={<AdminMessages />}
            />

            <Route path="/chat" element={<Chat />} />

            <Route path="*" element={<Nofound />} />
          </Routes>
        </section>
      </main>
    </>
  );
}

export default Main;
