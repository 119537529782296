import React, { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import Container from "@mui/material/Container";
import { toast } from "react-toastify";
import PropertyType from "./PropertyType";
import PropertyStarting from "./PropertyStarting";
import SpaceType from "./SpaceType";
import PropertyAddress from "./PropertyAddress";
import AddAmenities from "./AddAmenities";
import AddImages from "./AddImages";
import AddCharacteristics from "./AddCharacteristics";
import Preview from "./Preview";
import AddTitle from "./AddTitle";
import SetPricing from "./SetPricing";
import Axios from "axios";
import LinearProgress from "@mui/material/LinearProgress";
import { localStorageData } from "services/auth/localStorageData";
import { ImageEndPoint } from "config/config";
import { useMutation } from "react-query";
import { useLocation } from "react-router-dom";
import userService from "services/httpService/userAuth/userServices";
import ErrorService from "services/formatError/ErrorService";
import { AmenitiesData } from "data/AmenitiesData";
import { CharacteristicsData } from "data/CharacteristicsData";
import { alertClasses } from "@mui/material";
import logo from "../../../assets/img/headerLogo.png";
import userServices from "services/httpService/userAuth/userServices";
import Modal from "components/Modal/Modal";
import { FaRegCreditCard, FaStripeS } from "react-icons/fa";
import icon from "../../../assets/img/icon.png"

function PropertyListing() {

  let navigate = useNavigate();
  const location = useLocation();
  const propertyData = location.state ? location.state.offerState : null;
  const [stripeAccountId, setStripeAccountId] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  let userData = localStorageData("roles");

  let isInfluencer = userData?.includes("Influencer");

  console.log("Is the user an Influencer?", isInfluencer);

  // const toDataURL = (url) =>
  //   fetch(url)
  //     .then((response) => response.blob())
  //     .then(
  //       (blob) =>
  //         new Promise((resolve, reject) => {
  //           const reader = new FileReader();
  //           reader.onloadend = () => resolve(reader.result);
  //           reader.onerror = reject;
  //           reader.readAsDataURL(blob);
  //         })
  //     );

  // function dataURLtoFile(dataurl, filename) {
  //   var arr = dataurl.split(","),
  //     mime = arr[0].match(/:(.*?);/)[1],
  //     bstr = atob(arr[1]),
  //     n = bstr.length,
  //     u8arr = new Uint8Array(n);
  //   while (n--) {
  //     u8arr[n] = bstr.charCodeAt(n);
  //   }
  //   return new File([u8arr], filename, { type: mime });
  // }

  // State management for various property details and form inputs
  const [state, setState] = React.useState({
    propertyType: propertyData ? propertyData.propertyType : "",
    spaceType: propertyData ? propertyData.spaceType : "",
    address: propertyData ? propertyData.address : "",
    lat: propertyData ? propertyData.loc.coordinates[1] : "",
    long: propertyData ? propertyData.loc.coordinates[0] : "",
    amenities: propertyData ? propertyData.amenities : [],
    photos: [],
    title: propertyData ? propertyData.title : "",
    listing: propertyData ? propertyData.listing : "",
    characteristics: propertyData ? propertyData.characteristics : [],
    price: propertyData ? propertyData.price : 0,
    // points: propertyData ? propertyData.points : "",
    points: [],

    guestPrice: propertyData ? propertyData.guestPrice : 0,
    cleaningFee: propertyData ? propertyData.cleaningFee : 0,
    discountPrice: propertyData ? propertyData.discountPrice : 0,
    amenitiesFee: propertyData ? propertyData.amenitiesFee : 0,
    userCurrency: propertyData ? propertyData.userCurrency : "",
    info: [],
    input: propertyData ? propertyData.input : 0,
    from: propertyData ? propertyData.from : "",
    to: propertyData ? propertyData.to : "usd",
    output: propertyData ? propertyData.price : 0,
    spaceTypeDetail: propertyData ? propertyData.spaceTypeDetail : "",
  });

  const [activeStep, setActiveStep] = React.useState(0);

  // Handles the logic for moving to the next step in the form
  const handleNext = () => {
    if (activeStep === 0) {
      if (isInfluencer) {
        setActiveStep(activeStep + 1);
        return;
      }
      if (!stripeAccountId) {
        setIsModalOpen(true);
        return;
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 1) {
      if (state.propertyType === "") {
        toast.error("Select Type");
        return;
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 2) {
      if (state.spaceType === "") {
        toast.error("Select Space ");
        return;
      }

      if (
        (state.spaceTypeDetail &&
          (state.spaceTypeDetail.bedrooms == 0 ||
            state.spaceTypeDetail.bathrooms == 0 ||
            state.spaceTypeDetail.guests == 0)) ||
        (state.spaceType === "entire" && state.spaceTypeDetail.kitchen == 0)
      ) {
        toast.error("Select no of Bedrooms, bathrooms, kitchen, and guests");
      } else {
        setActiveStep(activeStep + 1);
      }
    }

    if (activeStep === 3) {
      if (!state.address) {
        toast.error("Enter Address");
        return;
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 4) {
      if (state.amenities.length === 0) {
        toast.error("Select amenities ");
        return;
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 5) {
      if (state.photos.length <= 4) {
        toast.error("Select atleast 5 photos ");
        return;
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 6) {
      if (state.title === "") {
        toast.error(" Enter Title ");
        return;
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 7) {
      if (state.characteristics.length === 0) {
        toast.error("Select Characteristics");
        return;
      }
      setActiveStep(activeStep + 1);
    }

    if (activeStep === 8) {
      if (state.guestPrice === "") {
        toast.error("Enter Price ");
        return;
      }
      setActiveStep(activeStep + 1);
    }
  };
  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  // Array representing the different steps in the form wizard
  const steps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

  // Returns the appropriate component for the current step
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <PropertyStarting state={state} setState={setState} />;
      case 1:
        return <PropertyType state={state} setState={setState} />;
      case 2:
        return <SpaceType state={state} setState={setState} />;
      case 3:
        return <PropertyAddress state={state} setState={setState} />;
      case 4:
        return (
          <AddAmenities
            state={state}
            setState={setState}
            AddAmenities={propertyData ? propertyData.amenities : AmenitiesData}
          />
        );
      case 5:
        return <AddImages state={state} setState={setState} />;
      case 6:
        return <AddTitle state={state} setState={setState} />;
      case 7:
        return (
          <AddCharacteristics
            state={state}
            setState={setState}
            AddCharacteristics={
              propertyData ? propertyData.characteristics : CharacteristicsData
            }
          />
        );
      case 8:
        return <SetPricing state={state} setState={setState} />;
      case 9:
        return <Preview state={state} setState={setState} />;
      default:
        return;
    }
  };

  // Mutation function to handle property upload
  const { mutate, isLoading } = useMutation(
    (token) => userService.commonPostService("/property/uploadProperty", token),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success("Property uploaded! Please wait for admin approval.");

        navigate("/");
      },
    }
  );

  // Mutation function to handle property update
  const { mutate: passMutate, isLoading: passLoading } = useMutation(
    (token) =>
      userService.updatePostService(
        `property/updatePropertyById/${propertyData._id}`,
        token
      ),
    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        toast.success("Uploaded");

        navigate("/");
      },
    }
  );

  // Handles form submission for uploading or updating the property
  const onSubmit = () => {
    const formData = new FormData();

    state.amenities.forEach((item) => {
      const simplifiedAmenity = {
        id: item.id,
        label: item.label,
        status: item.status,
        value: item.value,
      };

      formData.append("amenities", JSON.stringify(simplifiedAmenity));
    });

    state.photos.forEach((item) => {
      formData.append("pics", item.file);
    });
    state.characteristics.forEach((item) => {
      const simplifiedCharacteristics = {
        id: item.id,
        label: item.label,
        status: item.status,
        value: item.value,
      };
      formData.append(
        "characteristics",
        JSON.stringify(simplifiedCharacteristics)
      );
    });

    formData.append("address", state.address);
    formData.append("lat", state.lat);
    formData.append("long", state.long);
    formData.append("price", state.price);
    // formData.append("guestPrice", state.guestPrice);
    // here the quest price and points of property are same 
    formData.append("guestPrice", JSON.stringify(state.points));

    formData.append("cleaningFee", state.cleaningFee);
    formData.append("discountPrice", state.discountPrice);
    formData.append("amenitiesFee", state.amenitiesFee);

    formData.append("propertyType", state.propertyType);
    formData.append("spaceType", state.spaceType);
    if (state.spaceType) {
      formData.append("spaceTypeDetail", JSON.stringify(state.spaceTypeDetail));
    }
    formData.append("title", state.title);
    formData.append("description", state.description);
    formData.append("listing", state.listing);
    formData.append("points", JSON.stringify(state.points));
    // formData.append("points", state.guestPrice);
    formData.append("userCurrency", state.userCurrency);
    formData.append("isAvailable", true);
    formData.append("userId", localStorageData("_id"));
    if (propertyData) {
      formData.append("propertyId", propertyData._id);
    }
    {
      propertyData ? passMutate(formData) : mutate(formData);
    }
    for (const value of formData.values()) {
    }
  };

  // Mutation function to retrieve user data and check Stripe account or subscription status
  const { mutate: setMutate, isLoading: CardLoading } = useMutation(
    (userData) =>
      userServices.userById(
        `/userAuth/user/${localStorageData("_id")}`,
        userData
      ),

    {
      onError: (error) => {
        toast.error(ErrorService.uniformError(error));
      },
      onSuccess: (data) => {
        if (data?.data?.subscription === true) {
          if (data && data.data && data.data.stripe_account_id) {
            setStripeAccountId(data.data.stripe_account_id);
          }
          else {
            setIsModalOpen(true);
          }
        } else if (isInfluencer) {
          return
        } else {
          console.log("modal open ")
          setIsModalOpen(true);
        }
      },
    }
  );
  useEffect(() => {
    setMutate();
  }, []);
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  return (
    <div className="min-h-screen">
      <>
        <div className="">
          <div className="bg-white w-full container-post border ">
            <div className="fixed top-0 z-[20px] flex items-center w-full  justify-between lg:px-20 xs:px-4 border-red-600  bg-color-cream h-[10vh] shadow shodow-[#FBFFFB]">
              <div className="flex items-center gap-4">
                <Link to="/">
                  <img className=" border-red" src={logo} alt="Logo" />
                </Link>
              </div>

              <div>
                <button
                  className="btn-styl rounded-full text-[#E8AD21] lg:w-[150px] lg:h-[40px] xs:h-[35px] xs:w-[130px] flex justify-center items-center border-1"
                  type="button"
                  onClick={() => navigate("/")}
                >
                  Save & Exit
                </button>
              </div>
            </div>
            <div className="w-full">{getStepContent(activeStep)}</div>

            <div className="fixed bottom-0 right-0 w-full z-[20px] bg-white h-20">
              <LinearProgress
                variant="determinate"
                value={(activeStep / (steps.length - 1)) * 100}
                sx={{
                  width: "100%",
                  height: "8px",
                  backgroundColor: "#AFB5C1", // Background color
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#E8AD21", // Progress bar color
                  },
                }}
              />

              <div className={`flex my-3 justify-between`}>
                {activeStep !== 0 ? (
                  <button
                    className="text-black font-bold ml-20"
                    onClick={handleBack}
                  >
                    Back
                  </button>
                ) : (
                  <div></div>
                )}

                {activeStep === steps.length - 1 ? (
                  <>
                    {isLoading ? (
                      <CircularProgress />
                    ) : (
                      <button
                        className="border-red-700 mr-8 bg-[#2459BF] w-[150px] h-[50px] rounded-full py-2 px-4 text-white shadow hover:shadow-lg mb-1 ease-linear transition-all duration-150"
                        onClick={() => onSubmit()}
                      >
                        {propertyData ? "Update Listing" : "Publish Listing"}
                      </button>
                    )}
                  </>
                ) : (
                  <button
                    className="border-red-700 mr-8 bg-[#2459BF] w-[150px] h-[50px] rounded-full py-2 px-4 text-white shadow hover:shadow-lg mb-1 ease-linear transition-all duration-150"
                    onClick={handleNext}
                  >
                    {activeStep == steps.length - 1
                      ? "Finish"
                      : activeStep !== 0
                        ? "Next"
                        : "Get Started"}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </>
      <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="flex items-center justify-center min-h-full p-4">
          <div className="w-full max-w-md rounded-lg p-6 flex flex-col items-center">
            <img
              src={require("assets/img/icon.png")}
              className="lg:w-40 lg:h-40 xs:w-24 xs:h-24 mb-10 rounded object-contain"
            ></img>
            <h2 className="text-xl font-bold mb-5 text-center text-gray-800 flex items-center">
              Purchase Subscription Plan & Connect Payment Method
            </h2>
            <p className="text-sm mb-6 text-center text-gray-600">

              To publish your listing, you need to complete your account setup by adding a payment method and purchasing a subscription plan.
            </p>
            <button
              onClick={() => navigate("/profile")}
              className="w-[60%] py-3 rounded-full bg-blue-500 hover:bg-blue-600 text-white text-base font-semibold transition duration-300 flex items-center justify-center"
            >
              <FaRegCreditCard className="mr-2" />
              Go to Account
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default PropertyListing;
