// import { Rating } from "@mui/material";
// import React, { useEffect, useState } from "react";

// export default function ReviewCard({ state }) {
//   const [value, setValue] = React.useState(5);
//   let total = (parseInt(state?.guestPrice) * 5) + ((parseInt(state?.guestPrice * 5) / 100) * 3) + parseInt(state?.cleaningFee) + parseInt(state?.amenitiesFee);
//   return (
//     <>
//       <div className="  items-center h-full border rounded-[15px] pb-4 my-2 ">
//         {state.photos?.length > 0 && (
//           <img
//             className="w-full rounded-t- h-60 object-cover"
//             src={URL.createObjectURL(state.photos[0].file)}
//             alt="review card"
//           ></img>
//         )}
//         <div className="propertyName  flex justify-between items-center mt-4">
//           <p className="ml-3 font-semibold text-2xl my-1">{state?.title}</p>
//           <p className="mr-3 flex items-center">
//             {" "}
//             <Rating name="read-only" value={value} readOnly /> (5)
//           </p>
//         </div>
//         <div className="propertyName  flex justify-between items-center mt-8 font-semibold ">
//           <p className="ml-3 font-md">{state?.guestPrice && state?.guestPrice} x 1 nights</p>
//           <p className="mr-3 flex items-center"> {parseInt(state.guestPrice) * 1} Points</p>
//         </div>

//         {/* the commented code is for future use */}
//         {/* <div className="propertyName  flex justify-between items-center mt-2 font-semibold">
//           <p className="ml-3 font-md">Cleaning & other fees</p>
//           <p className="mr-3 flex items-center"> {state.cleaningFee} Points</p>
//         </div>
//         <div className="propertyName  flex justify-between items-center mt-2 font-semibold">
//           <p className="ml-3 font-md">Amenities</p>
//           <p className="mr-3 flex items-center"> {state?.amenitiesFee} Points</p>
//         </div> */}


//       </div>
//       <div className="my-2 ">
//         <button className="px-4 bg-color-primary w-full text-white py-2 mb-6  rounded-full">
//           Show Preview or Edit
//         </button>
//       </div>
//     </>
//   );
// }


// new code 


import { Rating } from "@mui/material";
import React, { useEffect, useState } from "react";

export default function ReviewCard({ state }) {

  console.log('state@@@@@@@@@',state)
  // finding minimum points of the property
const pointsArray = state.points.map(item => Number(item.points));
const minPoints = Math.min(...pointsArray);


  const [value, setValue] = React.useState(5);
  let total = (parseInt(state?.guestPrice) * 5) + ((parseInt(state?.guestPrice * 5) / 100) * 3) + parseInt(state?.cleaningFee) + parseInt(state?.amenitiesFee);
  return (
    <>
      <div className="  items-center h-full border rounded-[15px] pb-4 my-2 ">
        {state.photos?.length > 0 && (
          <img
            className="w-full rounded-t- h-60 object-cover"
            src={URL.createObjectURL(state.photos[0].file)}
            alt="review card"
          ></img>
        )}
        <div className="propertyName  flex justify-between items-center mt-4">
          <p className="ml-3 font-semibold text-2xl my-1">{state?.title}</p>
          <p className="mr-3 flex items-center">
            {" "}
            <Rating name="read-only" value={value} readOnly /> (5)
          </p>
        </div>
        <div className="propertyName  flex justify-between items-center mt-8 font-semibold ">
          {/* <p className="ml-3 font-md">{state?.guestPrice && state?.guestPrice} x 1 nights</p>
          <p className="mr-3 flex items-center"> {parseInt(state.guestPrice) * 1} Points</p> */}
          <p className="ml-3 font-md">{minPoints} x 1 nights</p>
          <p className="mr-3 flex items-center"> {parseInt(minPoints) * 1} Points</p>
        </div>

        {/* the commented code is for future use */}
        {/* <div className="propertyName  flex justify-between items-center mt-2 font-semibold">
          <p className="ml-3 font-md">Cleaning & other fees</p>
          <p className="mr-3 flex items-center"> {state.cleaningFee} Points</p>
        </div>
        <div className="propertyName  flex justify-between items-center mt-2 font-semibold">
          <p className="ml-3 font-md">Amenities</p>
          <p className="mr-3 flex items-center"> {state?.amenitiesFee} Points</p>
        </div> */}


      </div>
      <div className="my-2 ">
        <button className="px-4 bg-color-primary w-full text-white py-2 mb-6  rounded-full">
          Show Preview or Edit
        </button>
      </div>
    </>
  );
}