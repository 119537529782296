import React, { useState, useEffect } from "react";
import BnbNav from 'components/NavBar/BnbNav';
import img1 from '../../assets/img/luxury-home-with-swimming-pool-2021-08-26-17-04-57-utc 5.png';
import { Container } from "@mui/material";
import aboutpic from "../../assets/img/aboutpic.png";
import mssion from "../../assets/img/mission.png";
import value from "../../assets/img/values.png";
import { Divider } from "@mui/material";
import aboutPage from "../../assets/img/aboutPage.png";
import play from "../../assets/img/play.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footerbn from "pages/Footer/Footerbn";
import OurBlogs from "components/Blogs/OurBlogs";
import Navbar from "components/Navbars/AuthNavbar";

const AboutUs = () => {

  return (
    <>
      <BnbNav />
      <Navbar />
      <div className="relative">
        <img className="w-full h-36 " src={img1} alt="" />
        <p className="absolute text-2xl font-semibold text-white md:top-1/3 top-1/4  left-12 sm:left-20">
          Discover the Heartbeat Behind BnByond
        </p>
        <p className="absolute md:top-[80px] top-[100px] left-12 sm:left-20 text-[#FFFFFF] font-normal text-sm">
          Exploring the World, One Click at a Time
        </p>
      </div>
      <div className='w-full flex justify-center items-center gap-4 py-14'>
        <div className='w-full mx-4 md:mx-10 lg:w-[80%] flex flex-wrap justify-center lg:justify-between'>
          <div className='lg:w-[45%] w-full'>
            <img src={aboutpic} alt='' className="w-full h-full lg:w-auto object-cover lg:rounded-2xl" />
          </div>
          <div className='lg:w-[45%] w-full'>
            <h2 className='md:text-[20px] text-[16px] pt-2 lg:pt-0 font-avenir leading-[116.6%] font-extrabold'>We are passionate about revolutionizing the way vacation rental property owners travel and connect with one another.
              This is about getting the most out of your property.</h2>
            <p className='text-[14px] font-normal text-[#121112] font-avenir py-4'>Founded by a team of experienced entrepreneurs and travel enthusiasts, our mission is to create a more flexible, rewarding, and
              community-driven travel experience for property owners worldwide.
              We understand the challenges and aspirations of vacation rental property owners because we are property owners ourselves. That's why
              we have developed an innovative points-based system that allows our members to earn points for making their properties available and
              redeem those points for stays at other incredible properties within our network.
              Our platform is designed to be user-friendly and intuitive, providing a sense of comfort and familiarity for our members. We believe that
              travel should be accessible, authentic, and meaningful, and we strive to foster a strong sense of community among our members.
              At the core of our company are our values of innovation, community, flexibility, and authenticity. We are committed to continuously
              improving our platform and services to better serve our members and help them unlock the full potential of their vacation rental properties.
            </p>
            <div className='flex gap-4 mt-2'>
              <div className='w-[50%]'>
                <div className='flex gap-2 items-center py-2'>
                  <div className='bg-[#58C0D0] w-9 h-9 rounded-full flex justify-center items-center'>
                    <img src={mssion} alt='' />
                  </div>
                  <span className='text-[16x] font-extrabold font-avenir text-black'>Our Mission</span>
                </div>
                <p className='text-[12px] font-normal font-avenir'> At BnByond, we are committed to redefining the vacation rental experience by putting our members at the heart of everything we do. We value active listening, open communication, and a deep understanding of our members' needs and aspirations. Our mission is to provide a more fulfilling and enriching vacation rental experience for every vacation rental property owner - as a host and as a guest - creating a platform that not only facilitates seamless exchanges but also fosters meaningful connections, lasting memories and extraordinary value.</p>
              </div>
              <div className='w-[50%]'>
                <div className='flex gap-2 items-center py-2'>
                  <div className='bg-[#58C0D0] w-9 h-9 rounded-full flex justify-center items-center'>
                    <img src={value} alt='' />
                  </div>
                  <span className='text-[16x] font-extrabold font-avenir text-black'>Our Values</span>
                </div>
                <p className='text-[12px] font-normal font-avenir'>Integrity, member-centricity, community, and continual innovation are the core values that define BnByond. We are committed to creating a platform that prioritizes the needs and satisfaction of our members, fosters a supportive and inclusive community, ensures a secure and respectful environment, and delivers wholesale joy for vacation rental property owners.</p>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='flex md:flex-row flex-col justify-center items-center bg-[#F8F8F8] mx-3 px-1 md:px-0 md:mx-0'>
        <div className="flex md:w-[90%] lg:w-[80%] w-full gap-3 md:gap-20 lg:gap-3 xl:gap-7 py-14 xs:flex-wrap sm:justify-center xs:justify-center md:justify-center sm:flex-wrap lg:flex-row">

          <div className="bg-white w-full md:w-[300px] lg:w-[265px] z-10 py-10 rounded-xl">
            <div className="flex flex-col items-center justify-center">
              <div className="w-[60px] h-[60px]">
                <img src={require("assets/img/freeValue.png")} />
              </div>
              <div className="text-center">
                <h4 className="text-[#000000] pt-3 text-md font-normal">
                  Far More Value
                </h4>
                <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                  Get the absolute most from your vacation rental property
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white w-full md:w-[300px] lg:w-[265px] z-10 py-10 rounded-xl">
            <div className="flex flex-col items-center justify-center">
              <div className="w-[60px] h-[60px]">
                <img src={require("assets/img/accomodation.png")} />
              </div>
              <div className="text-center">
                <h4 className="text-[#000000] pt-3 text-md font-normal">
                  Enjoy Endless Accommodations
                </h4>
                <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                  Enjoy accommodations everywhere without paying a dime
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white w-full md:w-[300px] lg:w-[265px] z-10 py-10 rounded-xl">
            <div className="flex flex-col items-center justify-center">
              <div className="w-[60px] h-[60px]">
                <img src={require("assets/img/community-icon.png")} />
              </div>

              <div className="text-center">
                <h4 className="text-[#000000] pt-3 text-md font-normal">
                  Amazing Community
                </h4>
                <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                  Amazing community of vacation rental owners/like-minded travellers
                </p>
              </div>
            </div>
          </div>
          <div className="bg-white w-full md:w-[300px] lg:w-[265px] z-10 py-10 rounded-xl">
            <div className="flex flex-col items-center justify-center">
              <div className="w-[60px] h-[60px]">
                <img src={require("assets/img/guests.png")} />
              </div>
              <div className="text-center">
                <h4 className="text-[#000000] pt-3 text-md font-normal">
                  Quality Guests
                </h4>
                <p className="text-[#AFB5C1] pt-3 text-base font-normal w-auto mx-3">
                  The most responsible guests are other vacation rental owners
                </p>
              </div>
            </div>
          </div>


          <div className="w-full  relative max-w-[1200px] ml-auto mr-auto px-6 md:px-[120px] mt-4 z-40 hidden md:flex flex-wrap justify-between">
            <div
              className="w-[20px] rounded-full border border-[#FFFFFF] bg-[#58C0D0] h-[20px]"
              style={{ zIndex: "40" }}
            />
            <div
              className="w-[20px] rounded-full bg-[#C1E1C2] h-[20px]"
              style={{ zIndex: "40" }}
            />
            <div
              className="w-[20px] rounded-full bg-[#E8AD21] h-[20px]"
              style={{ zIndex: "40" }}
            />
            <div
              className="w-[20px] rounded-full bg-[#C0392D] h-[20px]"
              style={{ zIndex: "40" }}
            />
            <Divider className="w-full absolute top-2.5 left-0 bg-[#EDEDED] max-w-[1140px] ml-auto mr-auto mt-[-10px] z-0" />
          </div>
        </div>
      </div>
      <div className='flex md:flex-row flex-col justify-center items-center bg-white xs:mx-3 md:mx-10 lg:mx-0 '>
        <div className="relative w-full h-[450px] lg:w-4/5  border rounded-xl " style={{ backgroundImage: `url(${aboutPage}) `, backgroundRepeat: 'no-repeat', backgroundSize: 'cover' }}>
          <img src={play} alt='' className='w-20 h-20 z- absolute top-1/4 left-1/2 transform -translate-x-1/2 -translate-y-1/2' />

          <h2 className="text-white text-center font-Avenir font-medium md:text-3xl text-2xl leading-[116.6%] z-50 absolute md:top-1/2 top-40 md:left-1/2 md:transform md:-translate-x-1/2 md:-translate-y-1/2">
            ARE YOU READY TO TRAVEL? REMEMBER US!!
          </h2>
          <p className="text-white text-center font-Avenir md:font-light font-extralight md:text-base text-sm absolute md:left-1/2 md:transform md:-translate-x-1/2 md:top-[60%] top-56">Fusce hic augue velit wisi quibusdam pariatur, justo primis, nec nemo, rutrum. Vestibulum cumque laudantium. Sit ornare mollitia tenetur, aptent.</p>

          <button className='w-[200px] h-[50px] rounded-full bg-[#2459BF] text-center text-white text-[16px] font-bold uppercase absolute left-1/2 transform -translate-x-1/2 md:top-[75%] top-[320px]'>Explore</button>
        </div>
      </div >
      <div className="overflow-hidden">
        <OurBlogs />
      </div>
      <div className="flex justify-center w-full">

        <section className=" flex relative z-50 md:top-20  w-[94%]  lg:mb-10">
          <div className="">
            <img src={require("../../assets/img/icon.png")} className="absolute left-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] z-[-10]  " />
          </div>
          <Container sx={{
            width: '100%',        // xl:w-full
            maxWidth: '100%',     // xl:w-full
            '@media (min-width: 1024px)': {
              width: '90%',       // lg:w-[90%]
              maxWidth: '90%',    // lg:w-[90%]
            },
          }} style={{ paddingLeft: "6px", paddingRight: "6px" }}>
            <div
              className="bg-[#AF3227]/95 md:h-[300px] h-full  rounded-2xl md:w-[96%] mx-auto"
              style={{
                backgroundImage:
                  "url(" + require("assets/img/badgebg.png") + ")",
                backgroundRepeat: "no-repeat",
              }}
            >

              <Container>

                <div className="items-center w-full justify-between md:h-[300px] h-[500px] md:flex">

                  <div className="lg:pl-10">
                    <div className="md:w-[445px] flex items-center justify-center  w-full">
                      <p className="text-white text-[25px] text-center pt-3 md:pt-0 md:text-[36px] font-bold ">
                        Get travel tips, offers and inspiration from BnByond
                      </p>
                    </div>
                    <div className="relative pt-4">
                      <input
                        type="text" placeholder="Email address"
                        className="md:w-[400px] lg:w-[495px] bg-transparent  pl-5 border text-white  placeholder-white border-white w-full h-[50px] rounded-2xl"
                      />
                      <button className="text-[#C0392D] absolute right-0 text-base font-medium bg-white rounded-2xl md:w-[150px] w-full h-[50px]">
                        Subscribe
                      </button>
                    </div>
                  </div>
                  <div>
                    <img src={require("assets/img/downrrow.png")} />
                  </div>
                  <div className="relative">
                    <img src={require("assets/img/squad2.png")} />
                    <img
                      src={require("assets/img/squad.png")}
                      className="absolute z-40 top-4"
                    />
                  </div>
                </div>
              </Container>
            </div>

          </Container>

          <div className="">
            <img src={require("../../assets/img/icon.png")} className="absolute right-[-20px] lg:w-[170.64px] lg:h-[261.17px] lg:block hidden opacity-[21%] transform scale-x-[-1] z-[-10]   " />
          </div>

        </section>

      </div>
      <Footerbn />
    </>

  );
};

export default AboutUs;
