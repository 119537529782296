import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Axios from "axios";
import ListingColor from "components/Cards/ListingColor";
import Modal from "components/Modal/Modal";
import referalpoint from '../../../assets/img/referalpoint.png'
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FaExclamationTriangle } from "react-icons/fa";

export default function SetPricing({ state, setState }) {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedState, setSelectedState] = useState("guestPrice");
  // const [points, setPoints] = useState(0);

  // const openModal = () => {
  //   setIsModalOpen(true);
  // };
  // const closeModal = () => {
  //   setIsModalOpen(false);
  // };

  useEffect(() => {
    if (state.userCurrency) {
      selectCurrency(state.userCurrency);
    }
  }, []);

  // useEffect(() => {
  //   // Calculate the service fee whenever the guestPrice changes
  //   if (selectedState === 'guestPrice') {
  //     setPoints(state[selectedState]);
  //   } else {
  //     setPoints(state[selectedState]);
  //   }
  // }, [selectedState, state]);

  // Function to convert an input value based on the selected currency rate.
  function convert(value) {
    setState((prevState) => ({
      ...prevState,
      input: value,
    }));
    var rate = state.info[state.userCurrency];

    setState((prevState) => ({
      ...prevState,
      output: value * rate,
    }));
  }

  // Function to select a currency and fetch the latest exchange rates.
  const selectCurrency = (value) => {
    setState((prevState) => ({
      ...prevState,
      from: value,
    }));

    // Fetch the latest exchange rates for the selected currency using Axios
    Axios.get(
      `https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/${value}.json`
    ).then((res) => {
      setState((prevState) => ({
        ...prevState,
        info: res.data[value],
      }));
    });
  };

  // const handleSave = () => {
  //   setIsModalOpen(false);
  // };

  const modalValue = () => {
    return points;
  };
  let total = parseInt(state.guestPrice) + parseInt(state.cleaningFee) + parseInt(state.amenitiesFee);


  //new functionality
  const [selectedDate, setSelectedDate] = useState(null);
  const [priceInput, setPriceInput] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Initialize points as an array, or fallback to an empty array if state.points is not an array
  const [points, setPoints] = useState(Array.isArray(state.points) ? state.points : []);

  // Open Modal for the Selected Date
  const openModal = (date) => {
    setSelectedDate(date);

    // Ensure that points is always an array before using array methods
    const existingPoint = Array.isArray(points) ? points.find((item) => item.date === getDateKey(date)) : null;
    setPriceInput(existingPoint ? existingPoint.points : ''); // Set the input value for the selected date if exists
    setIsModalOpen(true);
  };

  // Close the Modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Function to generate a unique key based on the year-month-day format
  const getDateKey = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Format month as 2 digits
    const day = String(date.getDate()).padStart(2, '0'); // Format day as 2 digits
    return `${year}-${month}-${day}`;
  };

  // Save the points/price for the selected date in the desired array format
  const handleSave = () => {
    const dateKey = getDateKey(selectedDate);

    // Convert priceInput to a number to match the schema
    const updatedPoints = Array.isArray(points) && points.some((item) => item.date === dateKey)
      ? points.map((item) =>
        item.date === dateKey ? { date: dateKey, points: Number(priceInput) } : item
      )
      : [...(Array.isArray(points) ? points : []), { date: dateKey, points: Number(priceInput) }];

    setPoints(updatedPoints);
    setState((prevState) => ({
      ...prevState,
      points: updatedPoints,
    }));
    setIsModalOpen(false);
  };


  // Use effect to set initial values from state if passed from previous steps
  useEffect(() => {
    if (state.points && Array.isArray(state.points)) {
      setPoints(state.points);
    }
  }, [state]);


  console.log(points)

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item lg={5} md={6} sm={12} xs={12} style={{ height: "82vh", marginTop: '10vh' }}>
          <ListingColor
            bg="bg-color-darknavyblue"
            text="Set your Pricing"
            color="text-[#FFFFFF]"
            btn='step 8'
          />
        </Grid>
        <Grid item lg={7} md={6} sm={12} xs={12} display={'flex'} justifyContent={'center'} alignItems={'center'}
          style={{ marginTop: '7vh', paddingTop: "25vh", height: "82vh", overflowY: 'auto', overflowX: 'hidden' }}
        >
          <div className=" xs:mb-36 xs:mt-5 md:mb-36 md:mt-20 lg:mb-20 lg:mt-0">
            <h1 className="my-2 text-[13px] md:text-xl font-bold text-center">Please set the pricing for each date, <br /> ensuring coverage for at least the next 6 months:</h1>
            <div className="">
              {/* <div className="border border-color-grey bg-color-grey/50 flex justify-center items-center flex-col h-[400px] rounded-md p-4">

                <div className="flex items-center justify-center gap-10 pb-10">
                  <img
                    src={require("assets/img/coin.png")}
                    className="relative w-14 h-14 left-4 top-7"
                    alt=""
                  />
                  <p className="text-[130px] font-bold">{points}</p>
                  <img
                    src={require("assets/img/edit.png")}
                    className="relative w-12 h-12 cursor-pointer -left-8 top-9"
                    alt=""
                    onClick={openModal}
                  />
                </div>
                <div>
                  <select
                    name="currency"
                    className="block mx-auto my-2 bg-transparent outline-none text-color-darkgrey/50"
                    id="currency"
                    onChange={(e) => {
                      setSelectedState(e.target.value);
                    }}
                  >
                    <option className="bg-white" value="guestPrice">
                      Guest price before taxes {state.guestPrice} Points
                    </option>
                    <option className="bg-white" value="discountPrice">
                      Discount Price {state.discountPrice} Points
                    </option>
                    <option className="bg-white" disabled value="serviceFee">
                      BnByond services fee (3%)
                    </option>
                    <option className="bg-white" value="cleaningFee" disabled>
                      Cleaning fee {state.cleaningFee} Points
                    </option>

                    <option className="bg-white" value="amenitiesFee" disabled>
                      Amenities fee {state.amenitiesFee} Points
                    </option>

                  </select>
                </div>

              </div> */}

              {/* the commented code is for future use */}
              {/* <div className="flex flex-col md:flex-row gap-4 md:gap-2 items-center justify-center my-2 px-3 md:px-0 md:mr-4">
                <p className="flex items-center text-sm md:text-lg px-1 md:px-4 py-2 border rounded-full border-color-grey">
                  <img
                    src={require("assets/img/yellowloc.png")}
                    className="mr-2"
                    alt=""
                  />
                  Compare similar listings $45 - $67
                </p>
                <p className="ml-2 underline cursor-pointer">
                  Learn more about pricing
                </p>
              </div> */}
            </div>
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%', // Default full width for larger screens
            }}>
              {/* Calendar for pricing */}
              <div style={{ width: '80%', maxWidth: '400px', }}> {/* Wrapper to control width */}
                <Calendar
                  onClickDay={openModal}
                  tileContent={({ date }) => {
                    const dateKey = getDateKey(date);
                    const dayPoint = Array.isArray(points) ? points.find((item) => item.date === dateKey) : null;
                    return dayPoint ? (
                      <div style={{ textAlign: 'center', color: 'green', fontWeight: 'bold', lineHeight: '10px', fontSize: '10px' }}>
                        {dayPoint.points} Points
                      </div>
                    ) : null;
                  }}
                  tileDisabled={({ date }) => date < new Date()}
                />
              </div>
            </div>

            <div className="flex items-center justify-center text-yellow-400 w-[80%] md:w-[70%] lg:w-[80%] xl:w-[60%] mx-auto my-4">
              <FaExclamationTriangle className="text-3xl mr-2" />
              <span className="text-center font-bold">Point prices must match dollar rates on Airbnb and Vrbo to maintain pricing accuracy.</span>
            </div>

          </div>
        </Grid>
      </Grid>
      {/* <Modal isOpen={isModalOpen} onClose={closeModal}>
        <div className="min-h-full flex flex-col items-center justify-center">
          <img src={referalpoint}></img>
          <h2 className="lg:text-[36px] xs:text-[29px] py-10 font-medium leading-[41.98px] s text-center text-black font-avenir" style={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.2)' }}>
            Enter your price
          </h2>

          <input
            type="number"
            className="input-styl w-[70%] rounded-md pl-3 pr-10 py-2" // Added padding for the icon
            placeholder="Enter the amount in points"
            value={state[selectedState]}
            onChange={(e) => {
              const newState = { ...state };
              newState[selectedState] = e.target.value;
              setState(newState);
              convert(e.target.value);
            }}
          />
          <button className="w-[50%] h-12 uppercase text-center py-2 px-2 rounded-full bg-[#2459BF] text-white text-sm font-semibold mt-5" onClick={handleSave}>Save</button>
        </div>
      </Modal> */}

      {/* Modal for Input */}
      <Modal isOpen={isModalOpen} onRequestClose={closeModal}>
        <div style={{ padding: '20px', textAlign: 'center', alignItems: 'center', justifyItems: 'center' }} >
          <h3 className="font-bold text-2xl mt-20">Set Price for {selectedDate && selectedDate.toDateString()}</h3>
          <input
            type="number"
            placeholder="Enter points/price"
            value={priceInput}
            onChange={(e) => setPriceInput(e.target.value)}
            style={{ padding: '10px', background: '#D3D3D3', borderRadius: '20px', fontSize: '16px', width: '70%', marginTop: '30px' }}
          />
          <div style={{ marginTop: '30px' }}>
            <button className="bg-blue-500 text-white font-bold rounded-full px-5 py-2" onClick={handleSave} style={{ marginRight: '10px' }}>
              Save
            </button>
            <button className="bg-blue-500 text-white font-bold rounded-full px-5 py-2" onClick={closeModal}>Cancel</button>
          </div>
        </div>
      </Modal>
    </div>
  );
}